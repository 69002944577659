import { useNavigate } from 'react-router-dom'; 
import './Footer.css';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import { footerTexts } from 'content/footerContent';

const Footer = () => {
    const navigate = useNavigate();
  
    const handleMPSNavigation = () => {
      navigate('/merchantprocessingsolutions');
    }
    const handleGSNavigation = () => {
        navigate('/getstarted');
    }
    const handleCompanyNavigation = () => {
    navigate('/company');
    }
    const handleHomeNavigation = () => {
    navigate('/');
    }

    return (
        <footer>
            <div className="footer-container">
                <div className="footer-columns">
                    <div className="footer-column">
                        <h4>About</h4>
                        <ul>
                            <li><a onClick={handleHomeNavigation}>Home</a></li>
                            <li><a onClick={handleMPSNavigation}>Who we are</a></li>
                            <li><a onClick={handleGSNavigation}>Get in touch</a></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h4>Support</h4>
                        <ul>
                            <li><a onClick={handleMPSNavigation}>Policy</a></li>
                            <li><a onClick={handleMPSNavigation}>FAQs</a></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h4>Company</h4>
                        <ul>
                            <li><a onClick={handleCompanyNavigation}>Merchant Login</a></li>
                            <li><a onClick={handleCompanyNavigation}>Partner Login</a></li>
                        </ul>
                    </div>
                    <div className="follow-us">
                        <h4>Follow Our Social</h4>
                        <div className="social-icons">
                            <div className="social-icons-container">
                                <LinkedInIcon fontSize={'small'} style={{color: "rgb(177, 180, 184)" }} />
                            </div>
                            <div className="social-icons-container">
                                <XIcon fontSize={'small'} style={{color: "rgb(177, 180, 184)"}} />
                            </div>
                            <div className="social-icons-container">
                                <InstagramIcon fontSize={'small'} style={{color: "rgb(177, 180, 184)"}} />
                            </div>
                        </div>
                        <div className="follow-us-info">
                            <p>
                                1968 S. Coast Hwy #2851
                                Laguna Beach, CA 92651
                            </p>
                            <a href="tel:+18006832977">(800) 683-2977</a>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <p>{footerTexts.northstarTrademark}</p>
                </div>
            </div>
        </footer>
    );
  }

export default Footer;
