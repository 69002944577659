import './CannaInfo.css';

const CannaInfo = () => {
    return (  
        <>
            <div className="dispensaries">
                <div className="dispensaries_row">
                    <div className="dispensaries_row_headline">
                        <h1>Payments on Web Stores</h1>
                    </div>
                    <div className="dispensaries_row_parts">
                        <div className="dispensaries_row_parts_img_first">
                            <span className="et_pb_image_wrap">
                            <img src="https://cannafinex.com/wp-content/uploads/2020/02/wc_sf_logo.png" alt="" />
                            </span>
                        </div>
                        <div className="dispensaries_row_parts_text">
                            <div className="dispensaries_row_parts_text_content_first">
                                <h2><strong>Payments on Web Stores</strong></h2>
                                <p>Customers can use their credit or debit cards right on the Web Store Cash-out page. Merchant receive payments in digital tokens instantaneously to their NST Gateway account.</p>
                            </div>
                            <div className="dispensaries_row_parts_text_buttons">
                                <div className="dispensaries_row_parts_text_link_first">
                                    <a className="dispensaries_row_link" href="http://bixsystem.com" data-icon="5">Get WooCommerce Plugin</a>
                                </div>
                                <div className="dispensaries_row_parts_text_temp_img">
                                    <span className="et_pb_image_wrap">
                                    <img src="https://cannafinex.com/wp-content/uploads/2020/02/sf_image.png" alt="" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dispensaries_row">
                    <div className="dispensaries_row_headline">
                        <h1>Payments in Stores</h1>
                    </div>
                    <div className="dispensaries_row_parts">
                        <div className="dispensaries_row_parts_text">
                            <div className="dispensaries_row_parts_text_content">
                                <h2><strong>Integrated Point of Sale (POS) System</strong></h2>
                                <p>NST Gateway System has several types of POS Stations: POS Station with double monitor, PC Station with touch screen, and tablet. POS System for the full Station supports also back-office functions in stores or in headquarters with multiple stores. Besides payments over-the-counter, it also supports staff administration, inventory management, business transactions with suppliers and vendors, inventory planning, scheduling, and administration.</p>
                            </div>
                            <div className="dispensaries_row_parts_text_link">
                                <a className="dispensaries_row_link" href="https://cannafinex.com/wp-content/uploads/2020/02/CannaFinex_Brochure_Stations.pdf" target="_blank" data-icon="5">Download Brochure</a>
                            </div>
                        </div>
                        <div className="dispensaries_row_parts_img">
                            <span className="et_pb_image_wrap">
                            <img src="https://cannafinex.com/wp-content/uploads/2019/02/farma-2.jpg" alt="" />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="dispensaries_row">
                    <div className="dispensaries_row_headline">
                        <h1>Integrated Business System</h1>
                    </div>
                    <div className="dispensaries_row_parts">
                        <div className="dispensaries_row_parts_text">
                            <div className="dispensaries_row_parts_text_content">
                                <h2><strong>Seed-to-Sale Tracking System</strong></h2>
                                <p>NST Gateway System integrates businesses with different roles in the supply chain into a full business information system providing registration of all business entities, tracking of production and distribution of their products, management and optimization of inventory, tracking sales and customer deliveries, and quality assurance information. The system provides rich reporting of different types of events and transactions in the integrated NST Gateway System.</p>
                            </div>
                            <div className="dispensaries_row_parts_text_link">
                                <a className="dispensaries_row_link" href="https://cannafinex.com/wp-content/uploads/2020/02/CannaFinex_Brochure_Payments.pdf" target="_blank" data-icon="5">Download Brochure</a>
                            </div>
                        </div>
                        <div className="dispensaries_row_parts_img">
                            <span className="et_pb_image_wrap">
                            <img src="https://cannafinex.com/wp-content/uploads/2020/02/CannaFinex_Integrated_System.png" alt="" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CannaInfo;