import React, { useState } from 'react';
import './Accordian.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface AccordianProps {
    title: string;
    subtitle?: string | null;
  }

const Accordian = ({ title, subtitle }: AccordianProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`accordian ${isExpanded ? 'expanded' : ''}`} onClick={toggleExpanded}>
        <div className="accordian-title-row">
            <div className="accordian-title">{title}</div>
            <div className="accordian-arrow">{isExpanded ? <ExpandLessIcon fontSize='large' /> : <ExpandMoreIcon fontSize='large' />}</div>
        </div>
        {isExpanded && (
            <div className="accordian-description">
                <div className="accordian-subtitle">
                  {subtitle != null ? 
                  <p>{subtitle}</p> :
                  <>
                    <p>We're a payments technology company who puts humans first, with support when you need it. You can count on us for real time support on the phone or online, with a detailed <a href='' style={{color: 'rgb(98,211,239)'}}>FAQ</a> and individual support.</p>
                  </>
                }
                </div>
            </div>
        )}
    </div>
  );
};

export default Accordian;