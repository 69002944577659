export const accordianContent = {
    content: [
        {    
            title: "Proprietary Gateway",
            subtitle: 'Our custom-built gateway ensures seamless transactions, optimized for speed and efficiency, tailored to your specific business needs.',
        },
        {    
            title: "Responsive Support",
            subtitle: null,
        },
        {    
            title: "Reliable Security",
            subtitle: 'We prioritize the protection of your data and transactions with robust security measures, including encryption, fraud detection, and compliance with industry standards.',
        },
        {    
            title: "Developer Friendly API",
            subtitle: 'Our API is designed with developers in mind, offering comprehensive documentation, SDKs, and sandbox environments for easy integration and testing.',
        },
        {    
            title: "Flexible Accessibility",
            subtitle: `Whether you're on a desktop, mobile device, or tablet, our platform is accessible anytime, anywhere, providing convenience and flexibility for both you and your customers.`,
        }
      ]
};