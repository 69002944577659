import './Home.css'
import DropdownCard from 'components/Accordian/Accordian';
import BasicCard from 'components/BasicCard/BasicCard';
import GetStarted from 'components/GetStarted/GetStarted';
import { businessSolutionsContent } from 'content/businessSolutionsContent';
import { accordianContent } from 'content/accordianContent';
import PhoneIcon from '@mui/icons-material/Phone';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import { useNavigate } from 'react-router-dom'; 
import CannaInfo from 'components/CannInfo/CannaInfo';

const HomePage = () => {
    const navigate = useNavigate();
  
    const handleMPSNavigation = () => {
      navigate('/merchantprocessingsolutions');
    };

    const handleGSNavigation = () => {
        navigate('/getstarted');
      };
      
    return (
        <>
            <div className="flex-container-origin">
            <div className="circles"></div>
                <div className="flex-container">
                    <div className="left-column">
                        <h1>
                            Innovative Payment System
                        </h1>
                        <h1>
                            based on Bankcards, Bank Accounts (ACH)
                        </h1>
                        <h1>
                            and Digital Loyalty Points
                        </h1>
                        <p className="left-column-subtitle">
                            Flexible and secure payment solutions that provide all financial and business services you need to run your business with ease: payments, management of orders, accounting, and reporting.
                        </p>
                        <button className='left-column-button' onClick={handleMPSNavigation}>Learn More</button>
                        <div className="left-column-phone-number">
                            <PhoneIcon fontSize={'small'}style={{marginRight: '10px'}}/>
                            <p>Speak to us?</p>
                            <a href="tel:+18006832977" style={{textDecoration: 'none'}}><span>(800) 683-2977</span></a>
                        </div>
                    </div>
                    <div className="right-column">
                        <img src="photos/home-top-photo.png" alt="Large Picture" />
                    </div>
                </div>
            </div>
            <div className="bussiness-container">
                <div className="bussiness-flex-container">
                    <div className="bussiness-flex-container-left">
                        <span>We Do Business Better</span>
                    </div>
                    <div className="bussiness-flex-container-right">
                        <p>
                            NST Gateway is a successful entrepreneurial business that operates in a trustworthy manner. Partnering with us means that you're working with a merchant services company that values honesty and integrity.
                        </p>
                        <div>
                             <img className='bussiness-flex-container-right-bbb' src="NorthstarMedia/Home/bbb_rtg.svg" alt="Large Picture" />
                             <img className='bussiness-flex-container-right-inc'  src="NorthstarMedia/Home/inc5000.svg" alt="Large Picture" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="business-solutions-container">
                <div className="business-solutions">
                    <span className="business-solutions-top">
                        {businessSolutionsContent.heading.title}
                    </span>
                    <span className="business-solutions-bottom">
                        {businessSolutionsContent.heading.subtitle}
                    </span>
                </div>
                {businessSolutionsContent.content.map((content,index) => (
                    <BasicCard 
                        key={index}
                        title={content.title}
                        subtitle={index != 3 ? content.description : null}
                        buttonText={content.buttonText}
                        buttonImg="icons/northeast_arrow.png"
                        onButtonClick={handleMPSNavigation}
                    />
                ))}
            </div>
            <div className="blue-container">
                <div className="blue-container-row">
                    <div className="blue-container-image-container">
                        <img src="NorthstarMedia/Home/door_integrated_payments.svg" alt="Door PLaceholder" />
                    </div>
                    <div className="blue-container-text-container">
                            <span>A trusted partner</span>
                            <p>Whatever your business, NST Gateway has the solution. With more than 20 years as a trusted payments provider, NST Gateway offers a variety of payment solutions for merchants of all sizes across industries.</p>
                            <u onClick={handleGSNavigation}>Want in? Connect with us.<span style={{ marginRight: '10px' }}><NorthEastIcon fontSize={'small'} style={{ marginLeft: '.5rem' , width: '13px' }}/></span></u>
                    </div>
                </div>
            </div>
            <div className='drop-down-container'>
                <h1 className='drop-down-heading'>Integrated payment solutions to grow your business</h1>
                <h2 className='drop-down-subtitle'>One Partner, Endless Opportunities</h2>
                <div className='drop-down-list'>
                    {accordianContent.content.map((accordian, index) => (
                        <DropdownCard key={index} title={accordian.title} subtitle={accordian.subtitle} />
                    ))}
                </div>
            </div>
            <div style={{backgroundColor: 'rgb(246, 252, 255)'}}>
                <CannaInfo/>
            </div>
            <div className='get-started-origin'>
                <GetStarted />
            </div>
        </>
    );
}

export default HomePage;