import React from 'react';
import './BasicCard.css';

interface CardProps {
  title: string;
  subtitle?: string | null;
  buttonText?: string;
  buttonImg?: string;
  onButtonClick?: () => void;
}

const BasicCard = ({ title, subtitle, buttonText, buttonImg, onButtonClick }: CardProps) => {
  return (
    <div className="basic-card">
      <div className="basic-card-content">
        <div className="basic-card-text">
          <span>{title}</span>
          {subtitle != null ? 
                <p>{subtitle}</p> :
                <>
                  <p>We're a payments technology company who puts humans first, with support when you need it. You can count on us for real time support on the phone or online, with a detailed <a href='' style={{color: 'blue'}}>FAQ</a> and individual support.</p>
                </>
              }
        </div>
        <div className="basic-card-button">
          {buttonText ? 
            <button className="custom-button" onClick={onButtonClick}>
              <span>{buttonText}</span>
              {buttonImg && <img style={{ width: '10px', marginLeft: '.5rem' }} src={buttonImg} alt="Button Icon" />}
            </button>
          : 
            ""
          }
        </div>
      </div>
    </div>
  );
};

export default BasicCard;