import './GetStarted.css'; 
import { useNavigate } from 'react-router-dom'; 

const GetStarted = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/getstarted');
  };

  return (
    <div className="get-started-container">
        <div className="get-started-text">
            <p>Ready to accept payments anytime, anywhere?</p>
        </div>
        <div className="get-started-button-container">
            <button className="get-started-button" onClick={handleNavigation}>Get Started</button>
        </div>
    </div>
  );
};

export default GetStarted;