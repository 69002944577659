import React, { useState, useRef } from 'react';
import './BusinessForm.css'
import FormContent from "./FormData"
import emailjs from '@emailjs/browser';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface BusinessFormProps {
  title?: string | null;
}

const BusinessForm = ({title}: BusinessFormProps) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [relationshipSelected, setRelationshipSelected] = useState(false); // Add state for tracking select color

  const form = useRef<HTMLFormElement>(null);

  const [formData, setFormData] = useState<FormContent>({
    name: '',
    phone: '',
    websiteURL: '',
    location: '',
    email: '',
    company: '',
    relationship: '',
    comments: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setRelationshipSelected(true);
  };

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (!form.current) return;

    emailjs
    .sendForm('northstar', 'northstar_form', form.current, "VporrR3L6O9WddZ3n")
    .then(
      (response) => {
        setFormSubmitted(true)
        setRelationshipSelected(false);
        setFormData({ 
          name: '',
          phone: '',
          websiteURL: '',
          location: '',
          email: '',
          company: '',
          relationship: '',
          comments: ''
        });
        console.log('SUCCESS!', response.status, response.text);
      },
      (error) => {
        console.log('FAILED...', error);
      },
    );
  };

  return (
    <div className="payment-form-container">
      {
        title === null ?
        <p className="form-title-null">
            Ready to get started or learn more about how we can help with all your payment processing needs? Call us at <a href="tel:+18006832977" style={{textDecoration: 'none', color: 'black'}}><strong>800.683.2977 </strong></a>or fill out the form below and a company representative will be in touch within 24 hours.
        </p> :
        <p className="form-title">{title}</p>
      }
        <p className="form-subtitle">
            "<span style={{color: "red"}}>*</span>" Indicates Required Fields
        </p>
      <form ref={form} onSubmit={sendEmail} className="form-grid">
        <div className="form-group">
          <input placeholder="Name" type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
        </div>
        <div className="form-group">
          <input placeholder="Email" type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
        </div>
        <div className="form-group">
          <input placeholder="Phone" type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange} />
        </div>
        <div className="form-group">
          <input placeholder="Company" type="text" id="company" name="company" value={formData.company} onChange={handleChange} />
        </div>
        <div className="form-group" style={{ position: 'relative' }}>
          <input 
                className="web-url-ph" 
                placeholder="Website URL" 
                type="text" 
                id="websiteURL" 
                name="websiteURL" 
                value={formData.websiteURL} 
                onChange={handleChange} 
                required 
            />
            <span style={{ position: 'absolute', top: '50%', right: '8px', color: 'red', transform: 'translateY(-50%)' }}>*</span>
        </div>
        <div className="form-group" style={{ position: 'relative' }}>
          <select 
            id="relationship" 
            name="relationship" 
            value={formData.relationship} 
            onChange={handleOptionChange}
            style={{ color: relationshipSelected ? 'black' : '#808080' }}
            required>
              <option value="" disabled hidden>Are you an Agent/Partner or Merchant?</option>
              <option value="Agent/Partner">Agent/Partner</option>
              <option value="Merchant">Merchant</option>
          </select>                    
          <KeyboardArrowDownIcon style={{ position: 'absolute', top: '50%', right: '5%', color: '#808080', transform: 'translateY(-50%)' }}/>
          <span style={{ position: 'absolute', top: '50%', right: '8px', color: 'red', transform: 'translateY(-50%)' }}>*</span>
        </div>
        <div className="form-group"  style={{ position: 'relative' }}>
            <input 
            placeholder="Where is your business located?" 
            type="text" 
            id="location" 
            name="location" 
            value={formData.location} 
            onChange={handleChange} 
            required 
            />
            <span style={{ position: 'absolute', top: '50%', right: '8px', color: 'red', transform: 'translateY(-50%)' }}>*</span>
        </div>
        <div className="form-group">
          <input placeholder="Additional Comments" type="text" id="comments" name="comments" value={formData.comments} onChange={handleChange} />
        </div>
        {formSubmitted ?  <></> : <button type="submit">Submit</button>}
      </form>
      {formSubmitted ?  
            <div className="thank-you-message" style={{textAlign: 'center'}}>
                <p>Thank you for your submission!</p>
            </div>
      : <></>}
    </div>
  );
}

export default BusinessForm;