import React, { useState } from 'react';
import 'components/Header/Header.css'; 
import { headerTexts } from 'content/headerContent';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';

const Header = () => {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
      setOpen(newOpen);
    };

    const redirectToLoginSite = () => {
        window.open('https://portal.nstgateway.com', "_blank", "noreferrer");
        // window.location.href = 'https://portal.nstgateway.com';
    };
    const redirectToSignUpSite = () => {
        window.open('https://portal.nstgateway.com/registerUser.html', "_blank", "noreferrer");
        // window.location.href = 'https://portal.nstgateway.com/registerUser.html';
    };

    const handleHyperLink = (tab: string) => {
        switch(tab) {
            case 'Solutions': { 
                return '/merchantprocessingsolutions'
            } 
            case 'Get Started': { 
                return '/getstarted'
            } 
            case 'Company': { 
                return '/company'
            } 
            case 'Explore': { 
                return '/' 
            } 
            default: { 
                return '/'
            } 
        }
    }
    
    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
          <List>
            {headerTexts.navigationTabs.map((tab, index) => {           
                        const hyperlink = handleHyperLink(tab)
                        return(
                            <ListItem key={index} disablePadding>
                                <ListItemButton>
                                    <a href={hyperlink} style={{color: 'black', textDecoration:'none'}}>
                                        <ListItemText primary={tab} />
                                    </a>
                                </ListItemButton>
                            </ListItem>
                        )
                })}
          </List>
        </Box>
      );
      
    return (
        <header className="header">
            <div className="logo">
                <img src="NorthstarMedia/Home/NSTGateway_full_logo.png" alt="Logo" />
            </div>
            <div className='drop-down-menu'>
                <div className='drop-down'>
                    <button 
                        className="dropdown-button"
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon fontSize='small'/>
                    </button>
                    <Drawer open={open} onClose={toggleDrawer(false)}>
                        {DrawerList}
                    </Drawer>
                </div>
            </div>
            <div className="navigation-login">
                <div className="navigation">
                    {headerTexts.navigationTabs.map((tab, index) => {                
                        const hyperlink = handleHyperLink(tab)
                        return(
                            <a key={index} href={hyperlink}>{tab}</a>
                        );
                    })}
                </div>
                <div className="login">
                    <div className='signup-button'>
                        <button onClick={() => redirectToSignUpSite()}>
                            <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>
                                {headerTexts.signupButtonText}
                            </span>
                        </button>
                    </div>
                    <div className='login-button'>
                        <button onClick={() => redirectToLoginSite()}>
                            <span style={{ verticalAlign: 'middle', fontSize: '16px' }}>
                                {headerTexts.loginButtonText}
                            </span>
                        </button>
                    </div>
                    {/* {showLoginForm && (
                        <div className="floating-login-form">
                            <LoginForm onClick={toggleLoginForm} />
                        </div>
                    )} */}
                </div>
            </div>
        </header>
    );
}

export default Header;